import React from 'react'
import { useDispatch } from 'react-redux'
import { DatePicker, Form } from 'antd'
import moment from 'moment/moment'

import '../DateFilterDropdown/DateFilterDropdown.scss'
import { Button } from '../../components'
import { DATE_PICKER } from '../../utils/constants/constant'

const RangeFilterDropdown = ({
  setSelectedKeys,
  selectedKeys,
  confirm,
  clearFilters,
  dataIndex,
  dateFrmt,
  defaultFilteredValue,
  appliedFilter,
  setAppliedFilter,
  picker = DATE_PICKER.date,
}) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    const newFilterValues = { ...appliedFilter }

    if (dataIndex === 'startDate') {
      newFilterValues.startDate1 = selectedKeys?.[0] || null
      newFilterValues.startDate2 = selectedKeys?.[1] || null
    } else if (dataIndex === 'nextRevisionDate') {
      newFilterValues.endDate1 = selectedKeys?.[0] || null
      newFilterValues.endDate2 = selectedKeys?.[1] || null
    }

    dispatch(setAppliedFilter(newFilterValues))
    confirm()
  }

  React.useEffect(() => {
    if (defaultFilteredValue?.length) {
      const formattedDefaultValues = defaultFilteredValue.map(
        (value) => (value ? moment(value, dateFrmt) : null) // Convert to moment or handle null
      )

      form.setFieldsValue({
        [dataIndex]: formattedDefaultValues.some((value) => value === null)
          ? []
          : formattedDefaultValues, // Set the default value of the RangePicker
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultFilteredValue, dataIndex])
  return (
    <div className="filter-popup">
      <Form form={form}>
        <Form.Item name={dataIndex}>
          <DatePicker.RangePicker
            format={dateFrmt}
            picker={picker}
            onChange={(dates) => {
              const formattedDates = dates?.map((date) =>
                date?.format(dateFrmt)
              )
              setSelectedKeys(formattedDates)
            }}
            getPopupContainer={(triggerNode) => {
              return triggerNode.parentElement
            }}
            // allowClear={false}
          />
        </Form.Item>
        <div className="filter-btn-group">
          <div>
            <Button
              type="link"
              onClick={() => {
                form.resetFields()
                setSelectedKeys([])
                clearFilters()
                confirm()
              }}
              size="small"
              htmlType="submit"
              disabled={!selectedKeys?.length}
              label="Reset"
            />
          </div>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            label="Ok"
            htmlType="submit"
            size="small"
          />
        </div>
      </Form>
    </div>
  )
}

export default RangeFilterDropdown
